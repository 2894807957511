'use client'

import React, { useEffect, useMemo, useState } from 'react'
import {
  ArrowSmallLeftIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline'
import Gleap from 'gleap'
import Image from 'next/image'
import Link from 'next/link'
import usePersistedTheme from '@/hooks/usePersistedTheme'
import useTranslation from '@/hooks/useTranslation'
import { useViewport } from '@/hooks/useViewport'
import { useAuth } from '@/providers/Auth'
import { useEnvelope } from '@/providers/Envelope'
import Steps from '../Steps'
import { IBreakpoint } from '@/hooks/useViewport/useViewport'
import { cn } from '@/lib/utils'
import { StepEnum } from '@/providers/Envelope/Envelope.types'
import Button from '@/ui/atoms/Button'
import SandboxAlert from '@/ui/molecules/SandboxAlert'

export type Props = {
  showSandboxAlertBottom?: boolean
}

const HeaderEnvelope: React.FC<Props> = () => {
  const { t, isReady } = useTranslation('header')
  const { isSandbox } = useAuth()
  const { breakpoint } = useViewport()
  const { theme } = usePersistedTheme()
  const [gleapOpened, setGleapOpened] = useState(true)
  const [firstTouch, setFirstTouch] = useState(true)

  const toggleGleap = () => {
    if (gleapOpened && !firstTouch) closeGleap()
    else openGleap(true)
    setFirstTouch(false)
  }

  const openGleap = (openChat: boolean) => {
    Gleap.showFeedbackButton(true)
    if (openChat) Gleap.open()
    setGleapOpened(true)
  }

  const closeGleap = () => {
    Gleap.close()
    Gleap.showFeedbackButton(false)
    setGleapOpened(false)
  }

  useEffect(() => {
    if ((breakpoint as IBreakpoint) === 'xs') closeGleap()
    else openGleap(false)
  }, [breakpoint])

  const logoSrc =
    theme === 'dark'
      ? '/assets/logos/signater/yellow-black-square-with-white-text.png'
      : '/assets/logos/signater/yellow-black-square-with-black-text.png'

  const {
    steps: { steps, currentStep },
    showSkeleton,
    isLoadingStep,
    goToStepAction
  } = useEnvelope()

  const showSandboxAlert = useMemo(() => isSandbox, [isSandbox])

  return (
    <div
      className={cn(
        'fixed top-0 z-50 bg-white dark:bg-black w-full',
        showSandboxAlert ? 'h-20 flex flex-col' : 'h-16'
      )}
    >
      {showSandboxAlert && (
        <SandboxAlert className="relative h-6 max-h-6 min-h-6 md:max-h-8 md:h-full md:min-h-5">
          {t?.sandboxMode}
        </SandboxAlert>
      )}
      <div className="relative flex items-center flex-grow w-full h-full border-b border-gray-200 shadow-sm">
        <div className="flex items-center justify-between flex-1 w-full gap-x-4 lg:gap-x-6 container-main">
          <div className="flex items-center justify-start flex-1 h-full gap-x-4 min-w-48">
            <Link href="/">
              <Button
                variant="link"
                className="items-center hidden gap-2 text-sm font-normal lg:flex hover:no-underline w-max"
                childClassName="w-full"
                type="button"
                showSkeleton={!isReady}
              >
                <ArrowSmallLeftIcon
                  className="w-5 h-5 fill-current"
                  aria-hidden="true"
                />
                <Image width={160} height={32} src={logoSrc} alt="Signater" />
              </Button>
            </Link>
          </div>
          <Steps
            steps={steps}
            activeStep={currentStep.name}
            classNameContainer="gap-2 flex-2 mx-auto hidden lg:flex"
            isLoadingStep={isLoadingStep}
            goToStep={async (name) => await goToStepAction(name as StepEnum)}
            showSkeleton={showSkeleton}
          />
          <div className="flex items-center justify-end flex-1 gap-x-4 lg:gap-x-6">
            <Button
              variant="neutral"
              onClick={toggleGleap}
              showSkeleton={!isReady}
            >
              <InformationCircleIcon className="w-4 h-4" />
              {t?.buttonHelp}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderEnvelope
